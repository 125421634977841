.hero {
    display: flex; /* Grey background color */
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin: 0;
    background-image: url(../assets/heroBlurred.png);
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.overlay-text {
    position: absolute;
    width: fit-content;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    font-weight: bold;
    color: white; /* Text color on top of the image */
    padding: 10px 20px;
    border-radius: 5px;
}

.AboutPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin: 0;
    overflow: hidden;
    font-weight: bold;
    color: white;
    background-image: url(../assets/heroBlurred.png);
    background-size: cover;
    background-position: center;
}

.AboutPageContainer h1 {
    font-weight: bold;
}

.AboutPageContainer p {
    font-size: 24px;
    font-weight: bold;
}

.ErrorPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    margin: 0;
    overflow: hidden;
    font-weight: bold;
    color: white; /* Text color on top of the image */
    background: rgba(1, 1, 1, 0.5); /* Semi-transparent background for text */
}

.ErrorPageContainer p {
    padding: 5px;
    text-align: center;
    font-size: 20px;
}